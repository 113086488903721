@import '~@eika/core-style';

.comparison-table-button-and-links-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: $medium;
    margin-top: $medium;

    @media screen and (min-width: $break-start-desktop) {
        margin-top: $larger;
    }
}
