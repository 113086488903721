@import '~@eika/core-style';

.forsikring-header-widget {
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1360px;
    box-sizing: border-box;
    padding: 0 $size-16;
    overflow: hidden;
    top: 50px;

    @media screen and (min-width: $break-start-pad) {
        top: 70px;
    }

    .forsikring-header-widget__text {
        color: var(--form-theme-pastel);
        font-size: var(--font-size--smallest);
        white-space: nowrap;

        @media screen and (max-width: $break-start-medium-handset) {
            font-size: 10px;
        }

        @media screen and (min-width: 881px) {
            font-size: var(--font-size--regular);
        }
    }
}
