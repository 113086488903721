@import '~@eika/core-style';

.comparison-table-button-and-links-container {
    &__links {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: $medium-large;

        @media screen and (max-width: $break-start-large-handset) {
            flex-direction: column;
            gap: $small;
        }
    }
}
